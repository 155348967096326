export class MdlMembresiaReservacion {
	public id: number = -1;
	public id_clb_membresia_contacto: number = -1;
	public id_clb_membresia_contacto_origen: number = -1;
	public id_clb_seccion_division: number = -1;
	public id_crm_instructor: number = -1;
	public entrada: Date = new Date();
	public salida: Date = new Date();
	public temperatura: number = 0;
	public oxigenacion: number = 0;
	public registro: string = "";
	public observaciones: string = "";
	public enlinea: boolean = false;
}
