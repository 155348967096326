import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@syncfusion/ej2-base';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-charts';
import { IPointEventArgs } from '@syncfusion/ej2-angular-charts';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-tablero',
    templateUrl: './tablero.component.html',
    styleUrls: ['./tablero.component.scss']
})
export class TableroComponent implements OnInit {

    public breadcrumbs1: string = '';
    public aspectRatio: any = 100 / 80;
    public cellSpacing: number[] = [10, 10];
    public width: string = Browser.isDevice ? '100%' : '60%';
    public dataChico: any;
    public dataMediano: any;
    public dataGrande: any;

    public marker: Object = {
        visible: true,
        width: 10,
        height: 10,
        dataLabel: {
            visible: false,
            position: 'Top',
            font: {
                size: '16',
                fontWeight: 'normal'
            },
            border: { width: 1, color: 'white' },
            margin: { left: 5, right: 5, top: 2, bottom: 2 },
            template: '<div style="font-size: 16px;">${point.y}</div>'
        }
    };
    public chartArea: Object = { border: { width: 0 } };
    public enableAnimation: boolean = true;
    public legendSettings: Object = { visible: true };
    public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y}</b>' };

    public pointClick(args: IPointEventArgs): void {
        if (args.point.tooltip) {
            window.open(String(args.point.tooltip));
        }
    };

    //===============================================================================================
    // Configuracion de Ejes
    //===============================================================================================
    public primaryXAxis: Object = {
        valueType: 'Category',
        interval: 1,
        labelIntersectAction: 'Rotate45',
        majorGridLines: { width: 0 },
        labelStyle: {
            fontFamily: 'Roboto',
            fontStyle: 'medium',
            size: '14px'
        }
    };

    public primaryYAxis: Object = {
        labelFormat: 'n2',
        rangePadding: 'None',
        //minimum: 0,
        //maximum: 100,
        //interval: 20,
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 }
    };

    public tooltipRender(args: ITooltipRenderEventArgs): void {
        let textCol = args.text.split(":");
        let str1 = textCol[1].replace('<b>', '');
        let str2 = str1.replace('</b>', '');
        var value = parseFloat((str2).replace(',', ''));
        args.text = textCol[0] + ": <b>" + (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</b>';
    };
    //===============================================================================================


    constructor(
        private route: ActivatedRoute,
        private offcanvasService: NgbOffcanvas
    ) { }

    ngOnInit(): void {
        this.route.data.subscribe((resp: { tablero: any[], lstMenu: any[] }) => {
            this.dataChico = JSON.parse(JSON.stringify(resp.tablero)).respuesta.chico;
            this.dataMediano = JSON.parse(JSON.stringify(resp.tablero)).respuesta.mediano;
            this.dataGrande = JSON.parse(JSON.stringify(resp.tablero)).respuesta.grande;
        });
        this.route.data.subscribe(resp => {
            this.breadcrumbs1 = 'Dashboards';
        });
    }

    public chartClicked(e: any) {
        if (e.active[0]) {
            //const modalRef = this.offcanvasService.open(DetalleComponent, { position: 'end' });
            //modalRef.componentInstance.parametro = e.active[0].element.$context.raw.parametro;
        }
    }

    projectcount: number = 0;
    accuratecount: number = 0;
    clientcount: number = 0;
    customerfeedback: number = 0;

    projectcountstop: any = setInterval(() => {
        this.projectcount++;
        if (this.projectcount == 287) {
            clearInterval(this.projectcountstop);
        }

    }, 10)


    accuratecountstop: any = setInterval(() => {
        this.accuratecount++;
        if (this.accuratecount == 95) {

            clearInterval(this.accuratecountstop);
        }
    }, 10)


    clientcountstop: any = setInterval(() => {
        this.clientcount++;
        if (this.clientcount == 300) {

            clearInterval(this.clientcountstop);
        }
    }, 10)

    customerfeedbackstop: any = setInterval(() => {
        this.customerfeedback++;
        if (this.customerfeedback == 100) {

            clearInterval(this.customerfeedbackstop);
        }
    }, 10)

}
