import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MdlCancelacion } from '../Models/MdlCancelacion';

@Injectable({
  providedIn: 'root'
})
export class CancelacionesService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  /**
   * 
   * @returns 
   */

 public TBcancelaciones(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/reservacion/TbCancelaciones/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public Guardar(modelo: MdlCancelacion) {
    return this.http
      .post(`${environment.base_url}/membresia/reservacion/cancelaciones`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }



}
