import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErroresService } from '../../../shared/errores.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class EstadocuentaService {

    /**
    * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
    * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
    */
    private ruta: string = `${environment.base_url}/publico/contacto`;
    public transactionID: string = '';

    constructor(
        private http: HttpClient,
        private errores: ErroresService
    ) { }

    /**
     * Metodo para obtener la membresia con base en un Identificador o Numero
     * @param id Numero o Identificador de la membresia
     * @returns Observable con membresia
     */
    getAdeudoTabla(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/membresia/AdeudoTabla/${id}`)
            .pipe(
                catchError(error => {
                    return throwError(this.errores.getErrores(error));
                })
            );
    }
    /**
     * Metodo para obtener documento nuevo para procesar el pago.
     * @id Numero o Identificador de la membresia
     * @_ids_membresia Numero o Identificador de los documentos a pagar
     * 
     */
    public getAdeudo(_id_clb_membresia: string, _ids_documentos: string): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('id', _id_clb_membresia)
            .append('ids', _ids_documentos);
        return this.http.post<any>(`${environment.base_url}/membresia/AdeudoDocumento`, parametro)
            .pipe(
                catchError(error => {
                    return throwError(this.errores.getErrores(error));
                })
            );
    }

    public getTabla(id_membresia: any, historial: any): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('id_membresia', id_membresia)
            .append('historial', historial);
        return this.http.get<any>(`${environment.base_url}/membresia/AdeudoTabla`, { params: parametro });
    }

    public getTablaPago(id_membresia: any): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('id_membresia', id_membresia)
            .append('historial', 'true');
        return this.http.get<any>(`${environment.base_url}/membresia/AdeudoTabla`, { params: parametro });
    }


    /**
     * Metodo para guardar la respuesta de Paypal
     * @param modelo 3
     * @returns 
     */
    public setPago(id: number, modelo: any): Observable<any> {
        return this.http.post(`${this.ruta}/setPago/${id}`, modelo);
    }
}
