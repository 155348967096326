import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as $ from 'jquery';
import * as jQuery from 'jquery';
import { environment } from 'src/environments/environment';
import { ConfirmacionpagoService } from '../confirma-pago/Services/Confirmacionpago.service';
declare var OpenPay: any;
@Component({
  selector: 'app-openpay',
  templateUrl: './openpay.component.html',
  styleUrls: ['./openpay.component.scss']
})
export class OpenpayComponent implements OnInit {
  @ViewChild('btnPagar') btnPagar!: ElementRef;
  @ViewChild('btnProcesando') btnProcesando!: ElementRef;
  private Id!: number;
  private data: any = [];
  public formulario: UntypedFormGroup = new UntypedFormGroup({});
  private deviceSessionId: any;
  private correo: any;
  private TenemosToken: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rutaActiva: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private services: ConfirmacionpagoService
  ) {
    this.rutaActiva.params.subscribe(
      (params: Params) => {
        this.Id = params.id;
      }
    );
    //===============================================================================================
    // creamos la conexion a mi servicio para traer el data de mi api
    // ===============================================================================================
    this.route.data.subscribe((resp) => {
      this.data = resp.recibo.respuesta;
    });

    this.formulario = this.formBuilder.group({
      token_id: [''],
      order_id: [''],
      use_card_points: false,
      description: [''],
      amount: [0],
      moneda: [''],
      chkPoliticas: [false],
      chkTerminos: [false],
      card_name: [''],
      card_number: [''],
      card_month: [''],
      card_year: [''],
      card_cvv2: [''],
    });
  }

  get nombreNoValido() {
    return this.formulario.get('card_name')!.invalid && this.formulario.get('card_name')!.touched;
  }

  get tarjetaNoValido() {
    return this.formulario.get('card_number')!.invalid && this.formulario.get('card_number')!.touched;
  }

  get mesNoValido() {
    return this.formulario.get('card_month')!.invalid && this.formulario.get('card_month')!.touched;
  }

  get anioNoValido() {
    return this.formulario.get('card_year')!.invalid && this.formulario.get('card_year')!.touched;
  }

  get codigoNoValido() {
    return this.formulario.get('card_cvv2')!.invalid && this.formulario.get('card_cvv2')!.touched;
  }

  get politicasNoValido() {
    return this.formulario.get('chkPoliticas')!.invalid && this.formulario.get('chkPoliticas')!.touched;
  }

  get terminosNoValido() {
    return this.formulario.get('chkTerminos')!.invalid && this.formulario.get('chkTerminos')!.touched;
  }

  /**
   * Numero de Mes 0[1-9]|1[0-2] en expresion regular
   * cero seguido de 1 al 9 "OR" 1 seguido del 0 al 2
   */
  ngOnInit(): void {
    this.http.get<any>(`${environment.base_url}/pagos/tarjeta/${this.Id}`).subscribe(
      resp => {
        this.data = resp.respuesta;
        OpenPay.setId(this.data.openpay_id);
        OpenPay
          .setApiKey(this.data.openpay_pk);
        //=====================================================================================
        // Aqui hacemos un parse para asegurar que el parametro que se envia sea un "Boolean"
        //=====================================================================================
        OpenPay.setSandboxMode(JSON.parse(this.data.openpay_prueba));
        //=====================================================================================
        var deviceSessionId = OpenPay.deviceData.setup("payment-form", "device_session_id");
        this.deviceSessionId = deviceSessionId;
        this.formulario = this.formBuilder.group({
          token_id: [''],
          order_id: [this.data.Numero],
          use_card_points: false,
          description: [this.data.openpay_descripcion],
          amount: [this.data.importe],
          moneda: [this.data.moneda],
          chkPoliticas: [false, Validators.requiredTrue],
          chkTerminos: [false, Validators.requiredTrue],
          card_name: ['', Validators.required],
          card_number: [
            '',
            [Validators.required, Validators.pattern('[0-9]{16}')]
          ],
          card_month: [
            '',
            //Validators.compose([Validators.required, Validators.pattern('0?[1-9]|1[012]'), Validators.minLength(2), Validators.maxLength(2), Validators.min(1), Validators.max(12)])
            Validators.compose([Validators.required, Validators.pattern('0[1-9]|1[0-2]'), Validators.min(1), Validators.max(12)])
          ],
          card_year: [
            '',
            Validators.compose([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(2), Validators.maxLength(2), Validators.min(21), Validators.max(99)])
          ],
          card_cvv2: [
            '',
            Validators.compose([Validators.required, Validators.pattern('[0-9][0-9][0-9]'), Validators.min(0), Validators.max(999)])
          ],
        });
      }
    );

  }

  public procesar() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach(
        control => {
          control.markAsTouched();
        }
      )
    }
    this.btnPagar.nativeElement.style.display = 'none';
    this.btnProcesando.nativeElement.style.display = 'block';
    const element: HTMLInputElement = document.getElementById('token_id') as HTMLInputElement;
    OpenPay.token.extractFormAndCreate(
      'payment-form',
      this.onSuccess,
      this.onError);
    const obs$ = new Observable(observer => {
      let i = 0;
      const intervalo = setInterval(() => {
        i++;
        observer.next('Intento: ' + i);
        if (element.value !== "") {
          // Cuando el "element" ya cuenta con un valor, entonces ya tenemos respuesta del servicio OpenPay
          // entonces limpiamnos el intervalor y completamos el Observer "Terminamos el proceso"
          clearInterval(intervalo);
          observer.complete();
        }
        if (i > 20) {
          // Cuando el contador super las 20 interaciones
          // entonces limpiamnos el intervalor y completamos el Observer "Terminamos el proceso"
          clearInterval(intervalo);
          observer.complete();
          alert('Se ha superado el tiempo de espera. Intentar mas tarde.');
          return;
        }
      }, 500);
    });
    obs$.subscribe(
      resp => console.log(resp),
      error => console.log(error),
      () => {
        //console.info('Token Obtenido');
        this.ProcesaCargo();
      }
    );
  }

  /**
   * Procesamos la respuesta cuando es satisfactoria y 
   * obtenemos el <b>Token Id</b>, el cual se coloca dentro del formulario
   * para después pueda ser enviado en conjunto con el resto de los datos
   * @param response El token obtenido por parte de Open Pay
   */
  onSuccess(response: any) {
    var token_id = response.data.id;
    $('#token_id').val(token_id);
  }


  /**
   * Metodo para llama la API y enviar los datos a procesar del pago
   */
  ProcesaCargo() {
    const element: HTMLInputElement = document.getElementById('token_id') as HTMLInputElement;
    const body = {
      token_id: element.value,
      device_session_id: this.deviceSessionId,
      order_id: this.formulario.get('order_id')!.value,
      description: this.formulario.get('description')!.value,
      amount: this.formulario.get('amount')!.value,
      moneda: this.formulario.get('moneda')!.value,
      card_number: this.formulario.get('card_number')!.value,
      card_name: this.formulario.get('card_name')!.value,
      card_month: this.formulario.get('card_month')!.value,
      card_year: this.formulario.get('card_year')!.value,
      card_cvv2: this.formulario.get('card_cvv2')!.value,
      use_card_points: this.formulario.get('use_card_points')!.value
    };
    this.http.post<any>(`${environment.base_url}/pagos/cargo`, body).subscribe(
      resp => {
        if (resp.numero > 0) {
          let id_documento = resp.numero;
          this.services.GetCorreo(id_documento).subscribe(resp => { });
          this.services.GetCorreoDetalle(id_documento).subscribe(resp => { });
          this.router.navigate(['pages/confirmapago']);
        } else {
          alert('Error: ' + resp.mensaje);
          this.services.GetCorreoRechazo(this.Id).subscribe(resp => { });
          this.router.navigate(['pages/rechazo']);
        }
      });
  }

  onError(response: any) {
    var content = '';
    switch (response.data.error_code) {
      case 1000:
        content = 'Ocurrió un error interno en el servidor de Openpay';
        break;
      case 1001:
        content = 'cvv2 debe tener 4 digitos.';
        break;
      case 1002:
        content = 'La llamada no esta autenticada o la autenticación es incorrecta.';
        break;
      case 1003:
        content = 'La operación no se pudo completar por que el valor de uno o más de los parámetros no es correcto.';
        break;
      case 1004:
        content = 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.';
        break;
      case 1005:
        content = 'Uno de los recursos requeridos no existe.';
        break;
      case 1006:
        content = 'Ya existe una transacción con el mismo ID de orden.';
        break;
      case 1007:
        content = 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.';
        break;
      case 1008:
        content = 'Una de las cuentas requeridas en la petición se encuentra desactivada.';
        break;
      case 1009:
        content = 'El cuerpo de la petición es demasiado grande.';
        break;
      case 1010:
        content = 'Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.';
        break;
      case 1011:
        content = 'Se solicita un recurso que esta marcado como eliminado.';
        break;
      case 1012:
        content = 'El monto transacción esta fuera de los limites permitidos.';
        break;
      case 1013:
        content = 'La operación no esta permitida para el recurso.';
        break;
      case 1014:
        content = 'La cuenta esta inactiva.';
        break;
      case 1015:
        content = 'No se ha obtenido respuesta de la solicitud realizada al servicio.';
        break;
      case 1016:
        content = 'El mail del comercio ya ha sido procesada.';
        break;
      case 1017:
        content = 'El gateway no se encuentra disponible en ese momento.';
        break;
      case 1018:
        content = 'El número de intentos de cargo es mayor al permitido.';
        break;
      case 1020:
        content = 'El número de dígitos decimales es inválido para esta moneda';
        break;
      case 2001:
        content = 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.';
        break;
      case 2003:
        content = 'El cliente con este identificador externo (External ID) ya existe.';
        break;
      case 2004:
        content = 'El número de tarjeta es invalido.';
        break;
      case 2005:
        content = 'La fecha de expiración de la tarjeta es anterior a la fecha actual.';
        break;
      case 2006:
        content = 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.';
        break;
      case 2007:
        content = 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.';
        break;
      case 2008:
        content = 'La tarjeta no es valida para pago con puntos.';
        break;
      case 2009:
        content = 'El código de seguridad de la tarjeta (CVV2) es inválido.';
        break;
      case 2010:
        content = 'Autenticación 3D Secure fallida.';
        break;
      case 2011:
        content = 'Tipo de tarjeta no soportada.';
        break;
      case 3001:
        content = 'La tarjeta fue declinada por el banco.';
        break;
      case 3002:
        content = 'La tarjeta ha expirado.';
        break;
      case 3003:
        content = 'La tarjeta no tiene fondos suficientes.';
        break;
      case 3004:
        content = 'La tarjeta ha sido identificada como una tarjeta robada.';
        break;
      case 3005:
        content = 'La tarjeta ha sido rechazada por el sistema antifraude. Rechazada por coincidir con registros en lista negra.';
        break;
      case 3006:
        content = 'La operación no esta permitida para este cliente o esta transacción.';
        break;
      case 3009:
        content = 'La tarjeta fue reportada como perdida.';
        break;
      case 3010:
        content = 'El banco ha restringido la tarjeta.';
        break;
      case 3011:
        content = 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
        break;
      case 3012:
        content = 'Se requiere solicitar al banco autorización para realizar este pago.';
        break;
      case 3201:
        content = 'Comercio no autorizado para procesar pago a meses sin intereses.';
        break;
      case 3203:
        content = 'Promoción no valida para este tipo de tarjetas.';
        break;
      case 3204:
        content = 'El monto de la transacción es menor al mínimo permitido para la promoción.';
        break;
      case 3205:
        content = 'Promoción no permitida.';
        break;
      case 4001:
        content = 'La cuenta de Openpay no tiene fondos suficientes.';
        break;
      case 4002:
        content = 'La operación no puede ser completada hasta que sean pagadas las comisiones pendientes.';
        break;
      case 6001:
        content = 'El webhook ya ha sido procesado.';
        break;
      case 6002:
        content = 'No se ha podido conectar con el servicio de webhook.';
        break;
      case 6003:
        content = 'El servicio respondió con errores.';
        break;
      default:
        content = 'Error desconocido';
        break;
    }
    content += '\nNumero Error: ' + response.data.error_code;
    alert(content);
    return false;
  }
}
