import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule, ToolbarModule, ContextMenuModule, TabAllModule, TreeViewModule, SidebarModule, MenuModule, TreeViewAllModule, } from '@syncfusion/ej2-angular-navigations';
import { ComboBoxModule, AutoCompleteModule, MultiSelectModule, ListBoxModule, DropDownTreeModule, DropDownListAllModule, } from '@syncfusion/ej2-angular-dropdowns';
import { enableRipple } from '@syncfusion/ej2-base';

import { ScheduleModule, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
import { KanbanModule } from '@syncfusion/ej2-angular-kanban';
import { SplitterModule, DashboardLayoutModule, } from '@syncfusion/ej2-angular-layouts';
import {
  AccumulationChartModule,
  RangeNavigatorModule,
  SparklineModule,
  SmithchartModule,
  StockChartModule,
  BulletChartModule,
  CategoryService,
  LineSeriesService,
  BarSeriesService,
  DataLabelService,
  TooltipService,
  LegendService,
  ColumnSeriesService,
  ChartAnnotationService,
  RangeColumnSeriesService,
  StackingColumnSeriesService,
  SelectionService,
  ChartAllModule,
  RangeNavigatorAllModule,
  AccumulationChartAllModule
} from '@syncfusion/ej2-angular-charts';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { EditService, FilterService, GridAllModule, PagerModule, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule, TextBoxAllModule, NumericTextBoxAllModule, SliderAllModule } from '@syncfusion/ej2-angular-inputs';
import { GanttAllModule } from '@syncfusion/ej2-angular-gantt';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// =================================================================================================
// utilizacion de libreria Awesome
// =================================================================================================
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule, CalendarAllModule } from '@syncfusion/ej2-angular-calendars';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CheckBoxAllModule, ChipListAllModule, RadioButtonAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { MainPipesModule } from '../pipes/main-pipes.module';
import { CircularGaugeAllModule } from '@syncfusion/ej2-angular-circulargauge';
import { BlockUIModule } from 'ng-block-ui';
import { DiagramAllModule, SymbolPaletteAllModule } from '@syncfusion/ej2-angular-diagrams';
import { ProgressBarAllModule } from '@syncfusion/ej2-angular-progressbar';

// =================================================================================================
enableRipple(true);

@NgModule({
  declarations: [

  ],
  imports: [
    BlockUIModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    CalendarAllModule,
    RichTextEditorAllModule,
    AccordionModule,
    ToolbarModule,
    ContextMenuModule,
    TabAllModule,
    TreeViewModule,
    TreeViewAllModule,
    SidebarModule,
    MenuModule,
    GanttAllModule,
    DropDownListAllModule,
    ComboBoxModule,
    AutoCompleteModule,
    MultiSelectModule,
    ListBoxModule,
    DropDownTreeModule,
    ScheduleModule,
    KanbanModule,
    SplitterModule,
    DashboardLayoutModule,
    ChartAllModule,
    CircularGaugeAllModule,
    RangeNavigatorAllModule,
    AccumulationChartAllModule,
    AccumulationChartModule,
    RangeNavigatorModule,
    SparklineModule,
    SmithchartModule,
    StockChartModule,
    BulletChartModule,
    DialogModule,
    TextBoxModule,
    TextBoxAllModule,
    TooltipModule,
    GridAllModule,
    DiagramAllModule,
    SymbolPaletteAllModule,
    PagerModule,
    ListViewModule,
    ChipListAllModule,
    SwitchAllModule,
    NumericTextBoxAllModule,
    CheckBoxAllModule,
    RadioButtonAllModule,
    ProgressBarAllModule,
    SliderAllModule,
    MainPipesModule
  ],
  exports: [
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    CalendarAllModule,
    RichTextEditorAllModule,
    AccordionModule,
    ToolbarModule,
    ContextMenuModule,
    TabAllModule,
    TreeViewModule,
    SidebarModule,
    MenuModule,
    GanttAllModule,
    DropDownListAllModule,
    ComboBoxModule,
    AutoCompleteModule,
    MultiSelectModule,
    ListBoxModule,
    DropDownTreeModule,
    ScheduleModule,
    KanbanModule,
    SplitterModule,
    DashboardLayoutModule,
    ChartAllModule,
    CircularGaugeAllModule,
    RangeNavigatorAllModule,
    AccumulationChartAllModule,
    AccumulationChartModule,
    RangeNavigatorModule,
    SparklineModule,
    SmithchartModule,
    StockChartModule,
    BulletChartModule,
    DialogModule,
    TextBoxModule,
    TextBoxAllModule,
    TooltipModule,
    GridAllModule,
    DiagramAllModule,
    SymbolPaletteAllModule,
    PagerModule,
    ListViewModule,
    ChipListAllModule,
    SwitchAllModule,
    NumericTextBoxAllModule,
    CheckBoxAllModule,
    RadioButtonAllModule,
    ProgressBarAllModule,
    SliderAllModule,
    MainPipesModule
  ],
  providers: [
    ToolbarService,
    FilterService,
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    MonthAgendaService,
    CategoryService,
    LineSeriesService,
    BarSeriesService,
    DataLabelService,
    TooltipService,
    LegendService,
    ChartComponent,
    ColumnSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    StackingColumnSeriesService,
    EditService,
    SelectionService
  ],
  // ===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  // ===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  // ===============================================================================================
})
export class SharedModule { }
