<div class="row clsTitulo">
    <div class="col-10">
        <h3>Estado de Cuenta</h3>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-chart-line"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <block-ui>
            <!-- botonones encabezado -->
            <div class="custom-control custom-checkbox my-1 mr-sm-2 mb-3">
                <ejs-checkbox #checkbox label="Mostrar Historial" [checked]="false" (change)="changeHandler()">
                </ejs-checkbox>
            </div>
            <div *ngIf="this.checkbox.checked">
                <ejs-grid #tbHistorial [dataSource]='dsHistorial' height='200' (rowSelected)="onRowSelected($event)" (rowDeselected)='rowDeselected($event)'>
                    <e-columns>
                        <!-- <e-column field='id' headerText='No.' width='120' textAlign='Right'></e-column> -->
                        <e-column field='fecha' type='date' headerText='Fecha' width='120' format="dd/MM/yyyy" textAlign='left'></e-column>
                        <e-column field='adeudo2' headerText='Adeudo' width='90' format='N2' textAlign='left'>
                        </e-column>
                        <e-column field='descripcion' headerText='Descripcion' width='200'></e-column>
                        <e-column field='estatus' headerText='Estatus' width='80'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <!-- subtitulo tabla de adeudos -->
            <div class="callout callout">
                <h6><b>Tabla de Adeudos de la Membresia</b></h6>
            </div>
            <ejs-grid #tbAdeudos [dataSource]='dsAdeudos' height='200' (rowSelected)="onRowSelected($event)" (rowDeselected)='rowDeselected($event)'>
                <e-columns>
                    <e-column type='checkbox' width='50'></e-column>
                    <e-column field='fecha' headerText='Fecha' type='date' width='120' format="dd/MM/yyyy" textAlign='left'>
                    </e-column>
                    <e-column field='adeudo2' headerText='Adeudo' width='90' format='N2' textAlign='left'> </e-column>
                    <e-column field='descripcion' headerText='Descripcion' width='200'></e-column>
                    <e-column field='estatus' headerText='Estatus' width='80'></e-column>
                </e-columns>
            </ejs-grid>
            <!-- botonones encabezado -->
            <div class="callout callout-success">
                <div class="row">
                    <input type="text" #importefinal class="form-control" style="width:150px; text-align:right; font-size: 28px; margin-left: 10px;" readonly='true' value="{{ImporteTotal | number}}">
                    
                    <div #paymentRef></div>
                </div>
            </div>
        </block-ui>
    </div>
</div>
<div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
    <!-- <span>Ayuda</span> -->
</div>