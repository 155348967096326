<div class="row clsTitulo">
    <div class="col-10">
        <h2>Rechazo</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-window-close"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <div class="card mb-3" style="max-width: 600px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <!-- <img src="..." class="img-fluid rounded-start" alt="..."> -->
                </div>
                <div class="col-md-12">
                    <div class="card-body">
                        <p class="text-justify" style="font-style: italic; font-size: 26px; color: rgb(161, 12, 12);">
                            <b> Upss,
                                <br> Tenemos problema para confirmar tu pago.
                            </b>
                        </p>
                        <div class="box-body">
                            <h4>Posibles situaciones:</h4>
                        </div>
                        <ul>
                            <li>
                                <b>
                                    Si crees que es un problema de nuestra plataforma, espero un par de minutos para
                                    intertarlo nuevamente.
                                </b>
                            </li>
                            <li>
                                <b>
                                    Podras Intentar nuevamente espera 2 min. a 5 min. de preferecia.
                                </b>
                            </li>
                            <li>
                                <b>
                                    No hacer mas de 5 Intentos por tarjeta el sistema detectara como fraude y bloqueara
                                    la tarjeta.
                                </b>
                            </li>
                            <li>
                                <b>
                                    Con base en el mensaje, puedes confirmar con tu banco.
                                </b>
                            </li>
                            <li>
                                <b>
                                    para apoyarte con posible error en Orden de Pago. Llama a soporte:
                                    <h6>{{dataFacturacion.Telefono}}</h6>,
                                </b>
                            </li>
                            <li>
                                <b>
                                    Manda un correo a
                                    <br>
                                    <a href="mailto:{{dataFacturacion.Correo}}">{{dataFacturacion.Correo}}</a>
                                    <br>
                                    parasolicitar ayuda.
                                </b>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <h4><a href="http://localhost:4200/pages/tablero">Ir a la pagina principal</a></h4>
            </div>
        </div>
        <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
            <!-- <span>Ayuda</span> -->
        </div>
    </div>
</div>