<div class="row clsTitulo">
    <div class="col-10">
        <h2>Cancelaciones</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fas fa-calendar-alt"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab"
            aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda"
            aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <div class="tab-content">
            <!-- <div *ngIf="titular"> -->
            <div class="callout callout-titulo">
                <h6><b>Reservaciones</b></h6>
            </div>
            <ejs-grid [dataSource]='data' gridLines='Both' [rowHeight]='20'>
                <e-columns>
                    <e-column field='id' headerText='id' width='0'></e-column>
                    <e-column field='id' headerText=' ' width='50' minWidth='45'>
                        <ng-template #template let-item>
                            <button type="button" class="btn btn-borrar-sm " (click)="DialogoCancelacion(item)"
                                title="Cancelar"></button>
                        </ng-template>
                    </e-column>
                    <e-column field='nombre' headerText='Nombre Contacto' width='150'></e-column>
                    <e-column field='fecha' headerText='Fecha' width='150'></e-column>
                    <e-column field='hora' headerText='Hora' width='150'></e-column>
                    <e-column field='estatus' headerText='Estatus' width='150'></e-column>
                </e-columns>
            </ejs-grid>
            <br>
            <!-- <button #btnreservar type="button" class="btn btn-procesar rounded-0 mb-2 mr-2" [disabled]="!showbutton" (click)="show = !show" ng-model="show">Reservar</button> -->

            <ejs-dialog id='dlgCancelacion' #dlgCancelacion header='' width='600px' isModal='true'
                [visible]='false' showCloseIcon='true'>
                <ng-template #header>
                    <ul>
                        <li>Datos Generales:</li>
                    </ul>
                    <ul>
                        <li>Reservación a cancelar:</li>
                        <ul>
                            <li>Contacto: <b>{{this.reservacion.nombre}}</b></li>
                            <li>Estado: <b>{{this.reservacion.estatus}}</b></li>
                            <li>Fecha: <b>{{this.reservacion.fecha}}</b></li>
                            <li>Hora de Entrada: <b>{{this.reservacion.hora}}</b></li>
                            <li>Actividad: <b>{{this.reservacion.actividad}}</b></li>
                        </ul>
                    </ul>
                </ng-template>
                <ng-template #content>
                    <div class="container" style="padding: 10px;">
                        <div class="container" style="padding: 10px;">
                            <div class="callout callout-botones">
                                <button type="submit" class="btn btn-cancelar rounded-0 mb-2 mr-2"
                                    title="Cancelar Reservación" (click)="MsjCancelacion()">Cancelar Reservación
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ejs-dialog>
        </div>
        <block-ui>

        </block-ui>
    </div>



</div>
<div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
    <!-- <span>Ayuda</span> -->



</div>
<!-- 
</div> -->