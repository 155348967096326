import { Component, NgZone, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { UsuarioService } from '../services/usuario.service';
import { MdlUsuario } from '../models/MdlUsuario';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  @ViewChild('defaultDialog')
  public defaultDialog!: DialogComponent;
  public dialogHeader: string = 'Animation Dialog';
  public dialogCloseIcon: Boolean = true;
  public dialogWidth: string = '285px';
  public animationSettings: Object = { effect: 'Zoom' };
  public isModal: Boolean = true;
  public target: string = '.control-section';
  public showCloseIcon: Boolean = false;
  public visible: Boolean = true;
  public formSubmitted = false;
  public auth2: any;

  public dlgButtonClick = (): void => {
    this.defaultDialog.hide();
  };

  public dialogBtnClick: EmitType<Object> = (args: any) => {
    this.defaultDialog.content = 'Informacion del Mensaje';
    this.defaultDialog.animationSettings = { effect: 'Zoom', duration: 400 };
    this.defaultDialog.show();
  };

  public defaultDlgButtons: ButtonPropsModel[] = [
    {
      click: this.dlgButtonClick.bind(this),
      buttonModel: { content: 'Hide', isPrimary: true },
    },
  ];

  public loginForm = this.fb.group({
    database: ['REGENCY'],
    user: [localStorage.getItem('user') || '', [Validators.required]],
    password: ['', Validators.required]
  });

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private usuarioService: UsuarioService
  ) { }

  login() {
    this.usuarioService.login(this.loginForm.value).subscribe(
      (resp) => {
        if (JSON.parse(JSON.stringify(resp)).numero < 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        } else {
          //localStorage.setItem('user', this.loginForm.get('user')!.value);
          localStorage.setItem('token', JSON.parse(JSON.stringify(resp)).Token);
          const usuario: MdlUsuario = new MdlUsuario(
            JSON.parse(JSON.stringify(resp)).User,
            JSON.parse(JSON.stringify(resp)).Nombre,
            JSON.parse(JSON.stringify(resp)).Paterno,
            JSON.parse(JSON.stringify(resp)).Materno,
            JSON.parse(JSON.stringify(resp)).Puesto,
            JSON.parse(JSON.stringify(resp)).Departamento,
            JSON.parse(JSON.stringify(resp)).Empresa,
            JSON.parse(JSON.stringify(resp)).Perfil,
            JSON.parse(JSON.stringify(resp)).Id,
            JSON.parse(JSON.stringify(resp)).Id_rpt_tablero_detalle,
            JSON.parse(JSON.stringify(resp)).id_clb_membresia
          );
          //=======================================================================================
          // Almacenamos la información del usuario
          //=======================================================================================
          localStorage.setItem('usuario', JSON.stringify(usuario));
          //=======================================================================================
          // Navegar al Dashboard
          this.router.navigateByUrl(`/pages/tablero`);
        }
      },
      (err) => {
        DialogUtility.alert({
          title: 'Informacion Arcarius',
          content: err.error.msg,
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
    );
  }
}
