import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MainPipesModule } from '../../pipes/main-pipes.module';

@Component({
  selector: 'dlgMensajeError',
  standalone: true,
  templateUrl: './dlgMensajeError.component.html',
  styleUrls: ['./dlgMensajeError.component.scss'],
  imports: [
    NgbModalModule,
    MainPipesModule
  ]
})
export class dlgMensajeError {

  @Input() encabezado: string = '';;
  @Input() contenido: string = '';;
  @Input() error: string = '';;

  constructor(public activeModal: NgbActiveModal) { }

}
