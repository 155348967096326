import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CancelacionesService } from './Cancelaciones.service';

@Injectable({
  providedIn: 'root'
})
export class CancelacionesResolver implements Resolve<boolean> {

  constructor(
    private router: Router,
    private service: CancelacionesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    // id de la membresia localStorage se debe importar modelo en consola
    let id = JSON.parse(localStorage.getItem('usuario'));
    id.id_clb_membresia
    return this.service.TBcancelaciones(id.id_clb_membresia);
  }

}
