<div class="row clsTitulo">
    <div class="col-10">
        <h2>Transacción</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="fab fa-cc-visa"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <form [formGroup]="formulario" id="payment-form">
            <input type="hidden" name="token_id" id="token_id" formControlName="token_id">
            <input type="hidden" name="order_id" id="order_id" formControlName="order_id">
            <input type="hidden" name="use_card_points" id="use_card_points" formControlName="use_card_points">
            <div class="pymnt-itm card active">
                <h2>Tarjeta de crédito o débito</h2>
                <div class="pymnt-cntnt">
                    <div class="card-expl">
                        <div class="credit">
                            <h4>Tarjetas de crédito</h4>
                        </div>
                        <div class="debit">
                            <h4>Tarjetas de débito</h4>
                        </div>
                    </div>
                    <div class="sctn-row">
                        <div class="form-group">
                            <label for="description">Descripcion del Cargo</label>
                            <input type="text" id="description" formControlName="description" class="form-control" style="width: 300px;" autocomplete="off" data-openpay-card="description" readonly>
                        </div>
                        <div class="form-group">
                            <label for="amount">Importe del Cargo</label>
                            <div class="row" style="margin-left: 0px;">
                                <input type="text" id="amount" formControlName="amount" class="form-control" style="width: 220px;" autocomplete="off" data-openpay-card="amount" readonly>
                                <input type="text" id="moneda" formControlName="moneda" class="form-control" style="width: 80px;" autocomplete="off" data-openpay-card="moneda" readonly>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="card_name">Nombre del titular</label>
                            <input type="text" [class.is-invalid]="nombreNoValido" id="card_name" formControlName="card_name" class="form-control" style="width: 300px;" placeholder="Como aparece en la tarjeta" autocomplete="off" data-openpay-card="holder_name">
                            <small *ngIf="nombreNoValido" class="text-danger">El nombre del titualar no es correcto</small>
                        </div>
                        <div class="form-group">
                            <label for="card_number">Número de tarjeta</label>
                            <input type="number" [class.is-invalid]="tarjetaNoValido" id="card_number" formControlName="card_number" class="form-control" style="width: 300px;" placeholder="Como aparece en la tarjeta" autocomplete="off" data-openpay-card="card_number">
                            <small *ngIf="tarjetaNoValido" class="text-danger">El numero de tarjeta no es correcto</small>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label for="card_month">Mes Expiración</label>
                                <input type="text" [class.is-invalid]="mesNoValido" id="card_month" formControlName="card_month" placeholder="Mes" class="form-control" data-openpay-card="expiration_month">
                                <small *ngIf="mesNoValido" class="text-danger">Mes 2 digitos</small>
                            </div>
                            <div class="form-group col-md-2">
                                <label for="card_year">Año Expiración</label>
                                <input type="number" [class.is-invalid]="anioNoValido" id="card_year" formControlName="card_year" placeholder="Año" class="form-control" data-openpay-card="expiration_year">
                                <small *ngIf="anioNoValido" class="text-danger">Año 2 digitos</small>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="card_cvv2">Código Seguridad</label>
                                <input type="text" [class.is-invalid]="codigoNoValido" id="card_cvv2" formControlName="card_cvv2" placeholder="3 dígitos" class="form-control" autocomplete="off" data-openpay-card="cvv2">
                                <small *ngIf="codigoNoValido" class="text-danger">Codigo 3 digitos</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card border-primary mb-3" style="max-width: 18rem; border-color: #ff9900 !important;">
                                <div class="card-header">
                                    <img src="/assets/img_tarjeta/security.png">&nbsp;&nbsp;Seguridad
                                </div>
                                <div class="card-body text-primary">
                                    <h5 class="card-title" style="font-weight: bold;">Transacciones realizadas vía:</h5>
                                    <img src="/assets/img_tarjeta/openpay.png">
                                    <p class="card-text">Tus pagos se realizan de forma segura con encriptación de 256 bits</p>
                                </div>
                            </div>
                            <div class="card border-primary mb-3" style="max-width: 18rem; border-color: #0eab00 !important; margin-left: 10px; width: 300px;">
                                <div class="card-header">
                                    <!--<img src="/assets/iconos/tarjetacredito24.png">&nbsp;&nbsp;-->
                                    Pagar
                                </div>
                                <div style="padding: 10px 10px;">
                                    <div class="form-group clearfix">
                                        <div>
                                            <input type="checkbox" formControlName="chkTerminos" id="chkTerminos" style="float: left;width: 20px; height: 20px;">
                                            <label for="checkboxPrimary1" style="margin-left: 10px;">
                                    Acepto términos y condiciones
                                    <a [routerLink]="['/pages/terminos']" style="color:brown">Leer mas..</a>
                                </label>
                                            <small *ngIf="terminosNoValido" class="text-danger">Acepta los Terminos</small>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="chkPoliticas" formControlName="chkPoliticas" style="float: left;width: 20px; height: 20px;">
                                            <label for="checkboxPrimary2" style="margin-left: 10px;">
                                    Acepto la política y privacidad
                                    <a [routerLink]="['/pages/politica']" style="color:brown">Leer mas..</a>
                                </label>
                                            <small *ngIf="politicasNoValido" class="text-danger">Acepta las Politicas</small>
                                        </div>
                                    </div>
                                    <a #btnPagar class="button rght" id="pay-button" (click)="procesar()" style="display: block">Pagar</a>
                                    <div #btnProcesando class="spinner-border text-success" role="status" style="display: none">
                                        <span class="sr-only">Procesando...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>

    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>