import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MdlCalendario } from 'src/app/models/MdlCalendario';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private http: HttpClient, private router: Router, private errores: ErroresService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<any[]> | any {
    const base_url = environment.base_url;
    let res2 = this.http.get<MdlCalendario[]>(`${base_url}/usuario/calendario/Horarios`);
    return res2;
  }

  public getCalendario(id_membresia: any): Observable<any> | any {
    const parametro: HttpParams = new HttpParams()
      .append('id_membresia', id_membresia)
    return this.http.get<any>(`${environment.base_url}/usuario/calendario/Horarios`, { params: parametro });
  }

  public getReservaciones(id_membresia: any): Observable<any> | any {
    const parametro: HttpParams = new HttpParams()
      .append('id_membresia', id_membresia)
    return this.http.get<any>(`${environment.base_url}/usuario/calendario/Horarios`, { params: parametro })
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
  public getReservacionesUsuario(): Observable<any> | any {
    let usuario = JSON.parse(localStorage.getItem('usuario'));
    return this.http.get<any>(`${environment.base_url}/usuario/calendario/reservacionesUsuario/${usuario.id_clb_membresia}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  getLstSeccionBox(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/membresia/lstSeccionBox`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }




}
