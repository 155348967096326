import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import {
    GridComponent,
    SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { EstadocuentaService } from './services/estadocuenta.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-estadocuenta',
    templateUrl: './estadocuenta.component.html',
    styleUrls: ['./estadocuenta.component.scss'],
})
export class EstadocuentaComponent implements OnInit {

    @BlockUI() blockUI: NgBlockUI;
    public dsAdeudos: any;
    public dsHistorial: any;
    public ImporteTotal: number = 0;
    public usuario: MdlUsuario;
    public registro: any;
    @ViewChild('tbAdeudos') tbAdeudos: GridComponent;
    @ViewChild('importefinal') importefinal: ElementRef;
    @ViewChild('checkbox') checkbox: CheckBoxComponent;
    @ViewChild('paymentRef', { static: true }) paymentRef!: ElementRef;
    public id: number = -1;
    public selectionOptions: SelectionSettingsModel = {
        type: 'Multiple',
        enableSimpleMultiRowSelection: true,
    };


    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private Services: EstadocuentaService,
        private router: Router,
        private modalService: NgbModal
    ) {
        this.dsAdeudos = '';
        this.dsHistorial = '';
        // id de la membresia localStorage se debe importar modelo en consola
        this.usuario = JSON.parse(localStorage.getItem('usuario'));
        this.Services.getTabla(
            this.usuario.id_clb_membresia,
            true
        ).subscribe((resp) => {
            this.dsAdeudos = resp.respuesta;
        });
        this.Services.getTabla(this.usuario.id_clb_membresia, true).subscribe((resp) => {
            this.dsHistorial = resp.respuesta;
        });
    }

    ngOnInit(): void {
        this.Services.getTablaPago(this.usuario.id_clb_membresia).subscribe((resp) => {
            this.dsAdeudos = resp.respuesta;
        });
        if (typeof window !== "undefined") {
            // Confirmacion del constructor del boton
            //console.log(window.paypal);
            window.paypal.Buttons(
                {
                    style: {
                        layout: 'vertical',
                        color: 'blue',
                        shape: 'rect',
                        label: 'paypal'
                    },
                    createOrder: (data: any, actions: any) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: this.ImporteTotal,
                                        currency_code: 'MXN'
                                    }
                                }
                            ]
                        })
                    },
                    onApprove: (data: any, actions: any) => {
                        return actions.order.capture().then((details: any) => {
                            console.log(details);
                            if (details.status === 'COMPLETED') {
                                this.Services.setPago(this.registro.id, details).subscribe(resp => {
                                    if (resp.numero > 0) {
                                        this.Services.transactionID = details.id;
                                        this.router.navigate(['confirmacion']);
                                    } else {
                                        const modalRef = this.modalService.open(dlgMensajeError);
                                        modalRef.componentInstance.encabezado = resp.titulo;
                                        modalRef.componentInstance.contenido = resp.mensaje;
                                    }
                                });
                            }
                        });
                    },
                    onError: (error: any) => {
                        console.log(error);
                    }
                }
            ).render(this.paymentRef.nativeElement);
        }
    }

    ngOnDestroy(): void {
        this.dsAdeudos = '';
        this.dsHistorial = '';
    }

    public onRowSelected(args: any) {
        this.blockUI.start('Procesando...');
        this.ImporteTotal = 0.0;
        let registros: any = this.tbAdeudos.getSelectedRecords();
        registros.forEach((registro) => {
            this.ImporteTotal += registro.adeudo2;
        });
        this.importefinal.nativeElement.value = this.ImporteTotal;
        this.blockUI.stop();
    }

    /**
     *
     * @param args
     */
    public rowDeselected(args: any): void {
        this.blockUI.start('Procesando...');
        this.ImporteTotal = 0.0;
        let registros: any = this.tbAdeudos.getSelectedRecords();
        registros.forEach((registro) => {
            this.ImporteTotal += registro.adeudo2;
        });
        this.blockUI.stop();
    }

    // function to handle the CheckBox change event
    public changeHandler(): void {
        if (this.checkbox.checked === true) {
            this.Services.getTabla(this.usuario.id_clb_membresia, false).subscribe((resp) => {
                this.dsHistorial = resp.respuesta;
            });
        }
        this.dsHistorial = '';
    }

    btnPagar() {
        this.blockUI.start('Procesando...');
        let registros: any = this.tbAdeudos.getSelectedRecords();
        let ids: string = '';
        registros.forEach((registro) => {
            ids += ',' + registro.id;
        });
        this.Services.getAdeudo(String(this.usuario.id_clb_membresia), ids).subscribe(
            (resp) => {
                if (resp.numero > 0) {
                    this.router.navigate(['pages/openpay', resp.numero]);
                } else {
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title: '<span style="color: #28a745">' + resp.titulo + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: JSON.parse(JSON.stringify(resp)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' }
                    });
                }
            }
        );
    }
}
