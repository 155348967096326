import { Component, OnInit, ViewChild } from '@angular/core';
import { extend, Internationalization } from '@syncfusion/ej2-base';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  ScheduleComponent,
  EventSettingsModel,
  View,
  EventRenderedArgs,
  DayService,
  WeekService,
  WorkWeekService,
  MonthService,
  ResizeService,
  DragAndDropService,
  GroupModel,
  TimelineViewsService,
  TimelineMonthService,
  TimeScaleModel
} from '@syncfusion/ej2-angular-schedule';

import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CalendarioService } from './Services/Calendario.service';
import { environment } from 'src/environments/environment';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { ChangeEventArgs as TimeEventArgs } from '@syncfusion/ej2-calendars';
import * as $ from 'jquery';
import { Thickness } from '@syncfusion/ej2-diagrams';

@Component({
  selector: 'app-planeacion',
  templateUrl: './planeacion.component.html',
  styleUrls: ['./planeacion.component.scss'],
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    ResizeService,
    DragAndDropService,
    TimelineViewsService,
    TimelineMonthService
  ],
})
export class PlaneacionComponent implements OnInit {
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;
  public data: object[] = [];
  public selectedDate: Date = new Date();
  public eventSettings: EventSettingsModel;
  public views: Array<string> = ['Week', 'Day', 'Month'];
  public usuario: MdlUsuario;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private Services: CalendarioService,
  ) {

  }
  public ngOnInit(): void {
    //==================================================
    // Obtenemos la data antes de cargar el componente
    //==================================================
    this.Services.getReservacionesUsuario().subscribe((resp) => {
      this.data = resp.respuesta;
      this.eventSettings = { dataSource: <Object[]>extend([], this.data, true) };
    });
  }
}
