<div class="row clsTitulo">
    <div class="col-10">
        <h3>Confirmación de Pago</h3>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-check-circle"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
        <div class="card mb-3" style="max-width: 600px;">
            <div class="row g-0">
                <div class="col-md-4">
                    <!-- <img src="..." class="img-fluid rounded-start" alt="..."> -->
                </div>
                <div class="col-md-12">
                    <div class="card-body">
                        <h5 class="card-title">¡Gracias! </h5>
                        <h6 class="card-text">Tu orden ha sido recibida.</h6>
                        <p class="text-justify" style="font-size: 16px;">
                            ¡Gracias por realizar tu pedido! Estamos contentos de que haya encontrado lo que estaba buscando. Nuestro objetivo es que siempre esté satisfecho.</p>
                        <p class="text-justify" style="font-size: 14px;">Para confirmar fecha y hora de tu pedido favor de comunicarte al
                        </p>{{dataFacturacion.Telefono}}
                        <h5>¡Que tengas un gran día!</h5>
                        <p class="text-justify" style="font-size: 11px;"> En caso de cancelación del pedido, favor de comunicarte al </p>{{dataFacturacion.Telefono}}
                        <p class="text-justify" style="font-size: 11px;">con un máximo de 2 horas posteriores al haberlo realizado, pues transcurrido este tiempo, no procederá reembolso alguno. Para el caso de reembolsos, se generará un porcentaje de comisión por concepto de cobro que realiza openpay
                            por haber realizado la transacción. El método de recibo de pago o paynet no aplica en reembolsos.
                        </p>
                        <h6>Si requieres factura favor de solicitarla en:
                            <a href="mailto:{{dataFacturacion.Correo}}">{{dataFacturacion.Correo}}</a>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>