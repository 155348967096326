<div class="row clsTitulo">
    <div class="col-10">
        <h2>Informacion Membresía</h2>
    </div>
    <div class="col-2">
        <div class="icono">
            <i class="far fa-chart-bar"></i>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="tab_proceso-tab" data-toggle="tab" href="#tab_proceso" role="tab" aria-controls="tab_proceso" aria-selected="true">Proceso</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="tab_ayuda-tab" data-toggle="tab" href="#tab_ayuda" role="tab" aria-controls="tab_ayuda" aria-selected="false">Ayuda</a>
    </li>
</ul>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">

        <div class="tab-content">
            <form [formGroup]='formulario'>
                <div class="row">
                    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                        <div class="card h-100">
                            <div class="card-title mb-1 p-2">
                                <h5>Información General</h5>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="nombre">Nombre</label>
                                    <input type="text" id="nombre" class="form-control rounded-0" placeholder="Nombre de Membresia" formControlName='nombre' readonly>
                                    <!-- <small id="tfNombrevalida" class="form-text text-muted">Verificar su nombre</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="paterno">Paterno</label>
                                    <input type="email" id="paterno" class="form-control rounded-0" placeholder="Paterno de Membresia" formControlName='paterno' readonly>
                                    <!-- <small id="paternovalida" class="form-text text-muted">Verificar su correo</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="materno">Materno</label>
                                    <input type="text" id="materno" class="form-control rounded-0" placeholder="Materno de Membresia" formControlName='materno' readonly>
                                    <!-- <small id="maternovalida" class="form-text text-muted">Verificar su materno</small> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                        <div class="card h-100">
                            <div class="card-title mb-1 p-2">
                                <h5>Información de Parametros</h5>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="id_clb_membresia_estatus">Estatus</label>
                                    <ejs-combobox id='id_clb_membresia_estatus' formControlName='id_clb_membresia_estatus' [dataSource]='lstEstatusBox' [fields]='fields' placeholder='Select a game' readonly>
                                    </ejs-combobox>
                                </div>
                                <div class="form-group">
                                    <label for="id_clb_membresia_clasifica">Clasificación</label>
                                    <ejs-combobox id='id_clb_membresia_clasifica' formControlName='id_clb_membresia_clasifica' [dataSource]='lstClasificaBox' [fields]='fields' placeholder='Select a game' readonly>
                                    </ejs-combobox>
                                </div>
                                <div class="form-group">
                                    <label for="fecha_ingreso">Fecha Ingreso</label>
                                    <input type="Text" id="fecha_ingreso" class="form-control rounded-0" placeholder="referencia bancaria de Membresia" formControlName='fecha_ingreso' readonly>
                                    <!-- <ejs-datepicker id="fecha_ingreso" [format]="format" [enableMask]="true" readonly>
                                    </ejs-datepicker> -->
                                </div>
                                <div class="form-group">
                                    <label for="fecha_vence">Fecha De Vencimiento</label>
                                    <input type="Text" id="fecha_vence" class="form-control rounded-0" placeholder="referencia bancaria de Membresia" formControlName='fecha_vence' readonly>
                                    <!-- <ejs-datepicker id="fecha_vence" [format]="format" [enableMask]="true" readonly>
                                    </ejs-datepicker> -->
                                </div>
                                <div class="form-group">
                                    <label for="referenciabancaria">Ref. Bancaria</label>
                                    <input type="Text" id="referenciabancaria" class="form-control rounded-0" placeholder="referencia bancaria de Membresia" formControlName='referenciabancaria' readonly>
                                    <!-- <small id="paternovalida" class="form-text text-muted">Verificar su correo</small> -->
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text rounded-0" id="">Cliente Factura</span>
                                        </div>
                                        <button type="button" class="btn btn-buscar rounded-0"></button>
                                        <!-- <input type="text" class="form-control rounded-0" formControlName='id_cliente' readonly> -->
                                        <input type="text" class="btn btn-buscar rounded-0" value="{{clientenombre}}" readonly>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="clave">Contraseña</label>
                                    <input type="password" id="clave" class="form-control rounded-0" placeholder="Clave de Membresia" formControlName='clave' readonly>
                                    <!-- <small id="paternovalida" class="form-text text-muted">Verificar su correo</small> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                        <div class="card h-100">
                            <div class="card-title mb-1 p-2">
                                <h5>Información Domicilio</h5>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="calle">Calle</label>
                                    <input type="text" id="calle" class="form-control rounded-0" placeholder="Calle" formControlName='calle' readonly>
                                    <!-- <small id="tfcallevalida" class="form-text text-muted">Verificar calle</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="num_ext">Numero Ext.</label>
                                    <input type="number" id="num_ext" class="form-control rounded-0" placeholder="num_ext de Membresia" formControlName='num_ext' readonly>
                                    <!-- <small id="paternovalida" class="form-text text-muted">Verificar su correo</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="num_int">Numero Int.</label>
                                    <input type="number" id="num_int" class="form-control rounded-0" placeholder="num_int de Membresia" formControlName='num_int' readonly>
                                    <!-- <small id="paternovalida" class="form-text text-muted">Verificar su correo</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="colonia">Colonia</label>
                                    <input type="text" id="colonia" class="form-control rounded-0" placeholder="Colonia de Membresia" formControlName='colonia' readonly>
                                    <!-- <small id="maternovalida" class="form-text text-muted">Verificar su materno</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="cp">Codigo Postal</label>
                                    <input type="text" id="cp" class="form-control rounded-0" placeholder="Codigo Postal de Membresia" formControlName='cp' readonly>
                                    <!-- <small id="maternovalida" class="form-text text-muted">Verificar su materno</small> -->
                                </div>
                                <div class="form-group">
                                    <label>País</label>
                                    <input type="text" class="form-control rounded-0" placeholder="México" readonly>
                                    <!-- <ejs-combobox id='comboelement' [fields]='fields' [dataSource]='lstPaisBox' readonly>
                                    </ejs-combobox> -->
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Estado</label>
                                    <ejs-combobox id='comboelement' formControlName='cp' [fields]='fields' [dataSource]='lstEstadoBox' readonly>
                                    </ejs-combobox>
                                </div>
                                <div class="form-group">
                                    <label for="id_municipio">Municipio</label>
                                    <ejs-combobox id='id_municipio' formControlName='id_municipio' [fields]='fields' [dataSource]='lstMunicipioBox' placeholder='Selecciona un municipio' readonly>
                                    </ejs-combobox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 mb-4 align-items-stretch">
                        <div class="card h-100">
                            <div class="card-title mb-1 p-2">
                                <h5>Información de Contacto</h5>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="correo">Correo Electrónico</label>
                                    <input type="email" class="form-control  rounded-0" id="correo" formControlName='correo' placeholder="name@example.com" readonly>
                                </div>
                                <div class="form-group">
                                    <label for="tel_fax">Fax</label>
                                    <input type="text" class="form-control  rounded-0" id="tel_fax" placeholder="" formControlName='tel_fax' readonly>
                                </div>
                                <div class="form-group">
                                    <label for="tel_movil">Movil</label>
                                    <input type="text" class="form-control  rounded-0" id="tel_movil" placeholder="+55" formControlName='tel_movil' readonly>
                                </div>
                                <div class="form-group">
                                    <label for="tel_oficina">Oficina</label>
                                    <input type="text" class="form-control  rounded-0" id="tel_oficina" placeholder="+55" formControlName='tel_oficina' readonly>
                                </div>
                                <div class="form-group">
                                    <label for="twitter">Twitter</label>
                                    <input type="text" class="form-control  rounded-0" id="twitter" placeholder="" formControlName='twitter' readonly>
                                </div>
                                <div class="form-group">
                                    <label for="whatsapp">WhatsApp</label>
                                    <input type="text" class="form-control  rounded-0" id="whatsapp" placeholder="+55" formControlName='whatsapp' readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="tab-pane fade" id="tab_ayuda" role="tabpanel" aria-labelledby="tab_ayuda-tab">
        <!-- <span>Ayuda</span> -->
    </div>
</div>